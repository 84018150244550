import React from 'react'

const SparepartsPage: React.FC = () => {
    return (
        <section>
            <h1 style={{ fontSize: '4em' }}>We Sell Spareparts</h1>
        </section>
    )
}

export default SparepartsPage
