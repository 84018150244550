import React from 'react'
//import Navbar from '../components/Navbar'
import DateDisplay from '../components/DateDisplay'

const EquipmentPage: React.FC = () => {
    return (
        <section>
            <h1 style={{ fontSize: '4em' }}>We sell Equipment</h1>
        </section>
    )
}

export default EquipmentPage
