import React from 'react'

const SupplyBOMPage: React.FC = () => {
    return (
        <section>
            <h1 style={{ fontSize: '4em' }}>We care your BOM</h1>
        </section>
    )
}

export default SupplyBOMPage
